import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';

// json 데이터
import FAQ from "./FAQ.json"

let urlArr = window.location.href.split('/')
let userId = urlArr[3]
console.log('user id :', userId)

const chatbotName = {
    "jgpark": "베트남 남부 관광 안내",
    "jkchoi": "내일배움카드 운영 매뉴얼",
    "jhhan": "스마트혼합훈련 운영 매뉴얼",
    "sjkim": "반려동물",
    "synam": "연말정산 안내",
    "wskim": "서비스・경험디자인",
    "kmkwon": "제네시스 매뉴얼"
}

console.log(chatbotName[userId])

// 세션 아이디 한 번만 실행
let session_id;

const GetSessionId = async () => {
if (session_id) {
    return;
}

try {
    const response = await axios.get('http://citizen-chatbot-api.o2o.kr/api/v1/session/get_id');
    session_id = response.data.result;
    console.log('response1 : ', response);
    console.log('session_id : ', session_id);
} catch (error) {
    console.log('session_id is false!!');
    // throw error;
}
};


// 최초 1회 실행
GetSessionId();


const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState('');
    const [displayedText, setDisplayedText] = useState(`안녕하세요. ${chatbotName[userId]} 챗봇입니다. 무엇이 궁금하신가요?`);
    const [isLoading, setIsLoading] = useState(false); // 답변 대기중 스피너 출력


    // Admin page data handlings
    const [answerNum, setAnswerNum] = useState(0);
        


    // 스크롤 하단 고정
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // 모바일 100vh issue 해결을 위한 스크립트
    const setScreenSize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    useEffect(() => {
        // 컴포넌트가 마운트될 때 초기화
        setScreenSize();
    
        // 창 크기가 변경될 때마다 호출
        const handleResize = () => setScreenSize();
        window.addEventListener('resize', handleResize);
    
        // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
        return () => {
        window.removeEventListener('resize', handleResize);
        };
      }, []); // 빈 배열을 전달하여 컴포넌트가 처음 마운트될 때만 실행


    // 메세지
    const sendMessage = (messageToSend = text) => {

    // 입력 요소의 포커스 해제
    document.activeElement.blur();

    // 입력된 텍스트 또는 함수에 전달된 메시지를 사용
    const messageContent = messageToSend.trim();
    if (!messageContent) return;

    // if (!text.trim()) return;
    // setMessages([...messages, { text: text, sender: 'user' }]);
    setMessages(prevMessages => [...prevMessages, { text: messageContent, sender: 'user' }]);
    setText('');
    setIsLoading(true); // 로딩 시작

    // 챗봇 응답 로직을 여기에 추가
    const answerInfo = {
        'user_id': userId,
        'user_query': messageContent,
        };
        console.log('chat user_id', userId);
        console.log('chat user_query', messageContent);

        axios.post('https://apple.o2o.kr/camel-api/run_gwangju_citizen', answerInfo, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log('Response:', response);
            const answer = response.data["output"]
            console.log('answer:', answer);

            // setMessages(prevMessages => [...prevMessages, response.data]);
            // setDisplayedText(prevText => prevText + '\n' + text);  // 입력된 텍스트를 displayedText 상태에 저장
            // 챗봇 답변 메시지 추가
            setMessages(prevMessages => [...prevMessages, { text: answer, sender: 'bot' }]);

            updateChipsWrapperContent(); // suggestion chip 변경


            // Admin page 내용 전송
            console.log('question!!', messageContent); // user 질문
            console.log('data', answer); // api 답변
            postAnswer(session_id, answerNum, messageContent, answer);

            // 답변 index 업데이트
            setAnswerNum(answerNum + 1);
            console.log('answer_num +1 >> ', answerNum);
            

            setIsLoading(false); // 로딩 종료

        })
        .catch(error => {
            console.error('Error:', error);
            setIsLoading(false); // 로딩 종료
        });
    };

    // Admin 서버에 데이터 전송
    const postAnswer = async (sessionId, answerNumber, question, answer) => {
        try {
            const response = await fetch('http://citizen-chatbot-api.o2o.kr/api/v1/answer/save', {
                method: 'POST',
                mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'sessionId': sessionId,
                    'answerNum': answerNumber,
                    'question': question,
                    'answer': answer,
                }),
            });
    
            console.log('check point 01');
            console.log('answerNum_to_send : ', typeof answerNumber, answerNumber);
            console.log('sessionId : ', typeof sessionId, sessionId);
    
            const data = await response;
            // const data = await response.json();
            console.log('db입력후 response : ', data);
    
            
        } catch (error) {
            console.error('Error:', error);
        }
    };


    

    // 라운드 칩 클릭 시 메시지 전송
    const handleChipClick = (message) => {
        sendMessage(message);
    };

    // Admin 버튼 클릭 이벤트 핸들러
    const handleAdminClick = () => {
        window.location.href = `https://citizen-chatbot-admin.o2o.kr/`;
    };

    // suggestion chip 생성(4개)
    // const extractSuggestionChips = () => {
    //     let faq_list = FAQ.qa_list;
    //     let faq_extracted = faq_list.sort(()=> Math.random() - 0.5);
    //     faq_extracted = faq_extracted.slice(0,3);

    //     return faq_extracted
    // }

    // suggestion chip 내용 바꾸기 : id가 'chips-wrapper'인 요소의 내용을 새로운 데이터로 변환하는 함수
    const updateChipsWrapperContent = () => {

        // FAQ json에서 suggestion chip 3개 추출 
        let faq_list = FAQ[userId];
        console.log('faq_list', faq_list);
        let faq_extracted = faq_list.sort(()=> Math.random() - 0.5);
        faq_extracted = faq_extracted.slice(0,3);
        console.log('faq_extracted', faq_extracted);

        // 기존 데이터 지우기
        const chipsWrapper = document.getElementById('chips-wrapper');

        // 'chips-wrapper' 요소가 존재하면 내용을 새로운 데이터로 변환
        if (chipsWrapper) {
            // 기존 내용 삭제
            chipsWrapper.innerHTML = '';

            // 새로운 데이터로 요소 생성 및 추가
            faq_extracted.forEach((item) => {
                const newChip = document.createElement('div');
                newChip.className = 'rounded-chip';
                newChip.textContent = item;
                newChip.addEventListener('click', () => handleChipClick(item));
                chipsWrapper.appendChild(newChip);
            });
        }
    };

        // 현재 시간을 포맷팅하여 반환하는 함수
        const getCurrentTime = () => {
            const currentTime = new Date();
            const hours = currentTime.getHours();
            const minutes = currentTime.getMinutes();
            
            // 시간을 12시간 형식으로 변환
            const formattedHours = hours > 12 ? hours - 12 : hours;
            const period = hours >= 12 ? '오후' : '오전';
            
            // 0을 추가하여 두 자리 숫자로 만듦
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    
            return `${period} ${formattedHours}:${formattedMinutes}`;
    };


    return (
    <div className="App">
        <div className={`chat-container ${isLoading ? 'blurred' : ''}`}>
        {/* 채팅 화면 */}
            <div className='wrap'>
            <div className='container'>
            {/* 로딩 인디케이터 */}
            {isLoading && (
                <div className="loading-overlay">
                    <img src="img/spinner_01.gif" alt="로딩중" className="loading-spinner"/>
                </div>
            )}

            <div className='msger'>

            {/* 헤더 */}
            <header className='msger-header'>
                <div className='msger-header-title'>
                    <div className='header-logo-frame'>
                        <p>
                            {chatbotName[userId]} 챗봇
                        </p>
                    </div>
                </div>
                <div className="rounded-chip-admin" onClick={handleAdminClick}>Admin</div>
            </header>

            {/* 채팅창 */}
            <main className='msger-chat msger-chat-frame'>
                <section className='msger-chat-prompt'>
                    <div className='scroll-prompt'>
                        <div className='greeting'>{displayedText}</div>
                    
                    {/* 메시지 및 로딩 인디케이터 표시 */}
                    {messages.map((message, index) => (
                        // <div className='msg-bubble msg-right'>
                        //     <div className='user-say-text' key={index}>{message.text}</div>
                        // </div>
                        <div className={`msg-bubble ${message.sender === 'user' ? 'msg-right' : 'msg-left'}`} key={index}>
                        {/* <div className="msg-icon"></div> */}
                        <div className='user-say-text'>{message.text}</div>
                        <div className="msg-time">{getCurrentTime()}</div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                    {isLoading && <div className="loading-indicator"></div>}
                    </div>
                </section>
                
                <form className='msger-form' onSubmit={(e) => {e.preventDefault(); sendMessage();}}>
                <div className='chips-wrapper' id='chips-wrapper'>
                    <div className="rounded-chip" onClick={() => handleChipClick(`${FAQ[userId][0]}`)}>{FAQ[userId][0]}</div>
                    <div className="rounded-chip" onClick={() => handleChipClick(`${FAQ[userId][1]}`)}>{FAQ[userId][1]}</div>
                    <div className="rounded-chip" onClick={() => handleChipClick(`${FAQ[userId][2]}`)}>{FAQ[userId][2]}</div>
                </div>
                <div className='msger-inputarea'>
                {/* <img style={{ float: 'left', paddingTop: '12px', marginRight:'10px'}}src="https://banana.o2o.kr/img2/skylife_poc/home_button_04.svg"alt="home" onClick={() => window.location.reload()}></img> */}
                <img style={{ float: 'left', paddingTop: '12px', marginRight:'10px'}}src="img/home.svg"alt="home" onClick={() => window.location.reload()}></img>
                    
                    <input
                    className="msger-input"
                    id="msger-input"
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder='무엇이든 물어보세요'
                    />
                    {/* <img style={{ width: '35px', paddingTop: '0px' }} src="img/voice.svg" alt="send"></img> */}
                    
                    <button className="send-btn" type="submit">
                    {/* <img style={{ width: '35px', paddingTop: '0px' }} src="https://banana.o2o.kr/img2/skylife_poc/send_04.svg" alt="send"></img> */}
                    <img style={{ width: '35px', paddingTop: '0px' }} src="img/send.svg" alt="send"></img>
                    </button>
                </div>
                </form>
            </main>

        </div>
        </div>
        </div>
        </div>
    </div>
    );
    };

export default Chatbot;
